import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, TextField, Button, Box, Typography, IconButton, CircularProgress, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import ListEditor from "../components/ListEditor";
import fetchWithAuth from "../utils/fetchWithAuth";
import LanguageIcon from "@mui/icons-material/Language";

const AdminCourseForm = ({ isEditMode }) => {
    const [course, setCourse] = useState({
        title: "",
        description: "",
        link: "",
        telegramLink: "",
        duration: "",
        location: "",
        locationLink: "",
        contents: "",
        date: "",
        price: "",
        seats: "",
        imageSrc: "",
        language: "",  // новое поле для языка
        courseContent: [],
        priceInclusions: [],
        priceExclusions: [],
    });
    const [localizedData, setLocalizedData] = useState([]);
    const [activeLanguage, setActiveLanguage] = useState("ru"); // Default language is Russian
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (isEditMode && id) {
            fetchWithAuth(`/api/courses/${id}`)
                .then((response) => response.json())
                .then((data) => {
                    setCourse(data);
                    setLocalizedData(data.localizations);
                });
        } else {
            setLocalizedData([
                { language: "ru", title: "", description: "", contents: "", courseContent: [], priceInclusions: [], priceExclusions: [] },
                { language: "en", title: "", description: "", contents: "", courseContent: [], priceInclusions: [], priceExclusions: [] },
            ]);
        }
    }, [isEditMode, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCourse((prevCourse) => ({
            ...prevCourse,
            [name]: value,
        }));
    };

    const handleLocalizationChange = (e) => {
        const { name, value } = e.target;
        setLocalizedData((prevData) =>
            prevData.map((langData) =>
                langData.language === activeLanguage
                    ? { ...langData, [name]: value }
                    : langData
            )
        );
    };

    const handleLanguageChange = (language) => {
        setActiveLanguage(language);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSaving(true);

        // Извлекаем имя файла из imageSrc
        const processedImageSrc = course.imageSrc.split("/").pop();

        // Формируем данные для отправки
        const requestData = {
            ...course,
            imageSrc: processedImageSrc, // Обновляем только имя файла
            localizations: localizedData
        };

        const method = isEditMode ? "PUT" : "POST";
        const url = isEditMode ? `/api/admin/courses/${id}` : `/api/admin/courses/add`;

        fetchWithAuth(url, {
            method,
            body: JSON.stringify(requestData),
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to save course");
                }
                return response.json();
            })
            .then(() => navigate("/admin/courses"))
            .catch((error) => console.error("Failed to save course:", error))
            .finally(() => setIsSaving(false));
    };

    if (isSaving) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Typography variant="h4" gutterBottom>{isEditMode ? "Edit Course" : "Add New Course"}</Typography>
            <form onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                    {["ru", "en"].map((lang) => (
                        <IconButton
                            key={lang}
                            onClick={() => handleLanguageChange(lang)}
                            color={activeLanguage === lang ? "primary" : "default"}
                        >
                            <LanguageIcon />
                            <Typography variant="body1">{lang.toUpperCase()}</Typography>
                        </IconButton>
                    ))}
                </Box>

                {/* Localized Fields */}
                <TextField
                    label={`Title (${activeLanguage.toUpperCase()})`}
                    variant="outlined"
                    fullWidth
                    name="title"
                    value={localizedData.find((data) => data.language === activeLanguage)?.title || ""}
                    onChange={handleLocalizationChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label={`Description (${activeLanguage.toUpperCase()})`}
                    variant="outlined"
                    fullWidth
                    name="description"
                    value={localizedData.find((data) => data.language === activeLanguage)?.description || ""}
                    onChange={handleLocalizationChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label={`Contents (${activeLanguage.toUpperCase()})`}
                    variant="outlined"
                    fullWidth
                    name="contents"
                    value={localizedData.find((data) => data.language === activeLanguage)?.contents || ""}
                    onChange={handleLocalizationChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label={`Duration (${activeLanguage.toUpperCase()})`}
                    variant="outlined"
                    fullWidth
                    name="duration"
                    value={localizedData.find((data) => data.language === activeLanguage)?.duration || ""}
                    onChange={handleLocalizationChange}
                    sx={{ marginBottom: 2 }}
                />


                {/* Main Fields */}
                <TextField
                    label="Image URL"
                    variant="outlined"
                    fullWidth
                    name="imageSrc"
                    value={course.imageSrc}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Link"
                    variant="outlined"
                    fullWidth
                    name="link"
                    value={course.link}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Telegram Link"
                    variant="outlined"
                    fullWidth
                    name="telegramLink"
                    value={course.telegramLink}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Location"
                    variant="outlined"
                    fullWidth
                    name="location"
                    value={course.location}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Location Link"
                    variant="outlined"
                    fullWidth
                    name="locationLink"
                    value={course.locationLink}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Date"
                    variant="outlined"
                    fullWidth
                    name="date"
                    value={course.date}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Price"
                    variant="outlined"
                    fullWidth
                    name="price"
                    value={course.price}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Seats"
                    variant="outlined"
                    fullWidth
                    name="seats"
                    value={course.seats}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />

                {/* Language Field */}
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <InputLabel>Language</InputLabel>
                    <Select
                        value={course.language}
                        name="language"
                        onChange={handleChange}
                    >
                        <MenuItem value="ru">Russian</MenuItem>
                        <MenuItem value="en">English</MenuItem>
                        {/* Add other languages if needed */}
                    </Select>
                </FormControl>

                {/* Course Content, Price Inclusions, Price Exclusions */}
                <ListEditor
                    label={`Course Content (${activeLanguage.toUpperCase()})`}
                    items={localizedData.find((data) => data.language === activeLanguage)?.courseContent || []}
                    setItems={(newItems) =>
                        setLocalizedData((prev) =>
                            prev.map((langData) =>
                                langData.language === activeLanguage
                                    ? { ...langData, courseContent: newItems }
                                    : langData
                            )
                        )
                    }
                />
                <ListEditor
                    label={`Price Inclusions (${activeLanguage.toUpperCase()})`}
                    items={localizedData.find((data) => data.language === activeLanguage)?.priceInclusions || []}
                    setItems={(newItems) =>
                        setLocalizedData((prev) =>
                            prev.map((langData) =>
                                langData.language === activeLanguage
                                    ? { ...langData, priceInclusions: newItems }
                                    : langData
                            )
                        )
                    }
                />
                <ListEditor
                    label={`Price Exclusions (${activeLanguage.toUpperCase()})`}
                    items={localizedData.find((data) => data.language === activeLanguage)?.priceExclusions || []}
                    setItems={(newItems) =>
                        setLocalizedData((prev) =>
                            prev.map((langData) =>
                                langData.language === activeLanguage
                                    ? { ...langData, priceExclusions: newItems }
                                    : langData
                            )
                        )
                    }
                />

                <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
                    <Button variant="contained" color="primary" type="submit" disabled={isSaving}>
                        {isSaving ? "Saving..." : isEditMode ? "Save Changes" : "Add Course"}
                    </Button>
                </Box>
            </form>
        </Container>
    );
};

export default AdminCourseForm;
