import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

function SeatsAvailability({ totalSeats, occupiedSeats }) {
    const { t } = useTranslation(); // Используем хук для получения переводов

    const occupancyPercentage = (occupiedSeats / totalSeats) * 100;

    // Цвета для разных уровней заполняемости
    let progressColor = '#90caf9'; // Pastel blue for lightly occupied
    if (occupancyPercentage >= 50 && occupancyPercentage < 80) {
        progressColor = '#2196f3'; // Medium blue for medium occupancy
    } else if (occupancyPercentage >= 80) {
        progressColor = '#1976d2'; // Dark blue for full occupancy
    }

    return (
        <Box marginBottom={2}>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                <strong>{t('seats')}:</strong> {occupiedSeats} / {totalSeats}
            </Typography>

            <LinearProgress
                variant="determinate"
                value={occupancyPercentage}
                sx={{
                    marginBottom: 1,
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: progressColor,
                    },
                }}
            />
        </Box>
    );
}

export default SeatsAvailability;