// Header.js
import React, {useState, useEffect} from "react";
import {jwtDecode} from "jwt-decode";
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFish} from "@fortawesome/free-solid-svg-icons";

import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import {Link} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

function Header() {
    const {t, i18n} = useTranslation();
    const [open, setOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setOpen(open);
    };

    useEffect(() => {
        const token = localStorage.getItem("jwtToken");
        if (!token) {
            console.warn("Token not found in localStorage.");
            return;
        }

        try {
            const decodedToken = jwtDecode(token);
            console.log("Decoded Token:", decodedToken);

            const roles = decodedToken.role;
            if (roles) {
                const roleList = roles.split(",");
                if (roleList.includes("ADMIN")) {
                    setIsAdmin(true);
                }
            }
        } catch (error) {
            console.error("Error decoding token:", error);
        }
    }, []);

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    return (
        <AppBar position="sticky" color="primary">
            <Toolbar sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                {/* Logo and title with home navigation */}
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <Link to="/" style={{textDecoration: "none", color: "inherit"}}>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                fontSize: {xs: "1.2rem", sm: "1.5rem", md: "1.8rem"},
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 600,
                                letterSpacing: '2px',
                                color: 'white',
                                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                                transition: 'transform 0.3s ease',
                            }}
                        >
                            <FontAwesomeIcon icon={faFish} style={{marginRight: '10px'}}/>
                            FreedivingFlow.com
                        </Typography>
                    </Link>
                </Box>

                {/* Desktop navigation */}
                <Box sx={{display: {xs: "none", sm: "flex"}, ml: "auto"}}>
                    <Button color="inherit" component={Link} to="/" style={{textDecoration: "none", color: "inherit"}}>
                        {t('home')}
                    </Button>
                    <Button color="inherit" component={Link} to="/contact"
                            style={{textDecoration: "none", color: "inherit"}}>
                        {t('contact')}
                    </Button>
                    <Button color="inherit" component={Link} to="/reviews"
                            style={{textDecoration: "none", color: "inherit"}}>
                        {t('reviews')}
                    </Button>
                    <Button color="inherit" component={Link} to="/about"
                            style={{textDecoration: "none", color: "inherit"}}>
                        {t('about.title')}
                    </Button>

                    {/* Conditionally render CMS button for Admin */}
                    {isAdmin && (
                        <Button
                            color="inherit"
                            component={Link}
                            to="/admin/courses"
                            style={{textDecoration: "none", color: "inherit"}}
                        >
                            {t('cms')}
                        </Button>
                    )}

                    {/* Language Selector with Select */}
                    <FormControl variant="outlined" sx={{minWidth: 100}}>
                        <InputLabel>Language</InputLabel>
                        <Select
                            label="Language"
                            value={i18n.language}
                            onChange={changeLanguage}
                            labelId="language-select"
                            sx={{color: "white"}}
                        >
                            <MenuItem value="en">EN</MenuItem>
                            <MenuItem value="ru">RU</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {/* Burger menu for mobile */}
                <IconButton
                    color="inherit"
                    edge="end"
                    onClick={toggleDrawer(true)}
                    sx={{display: {xs: "block", sm: "none"}, ml: "auto"}}
                >
                    <MenuIcon/>
                </IconButton>
            </Toolbar>

            {/* Drawer for mobile */}
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                    sx={{width: 250}}
                >
                    <List>
                        <ListItem button component={Link} to="/" style={{textDecoration: "none", color: "inherit"}}>
                            <ListItemText primary={t('home')}/>
                        </ListItem>
                        <ListItem button component={Link} to="/contact" style={{textDecoration: "none", color: "inherit"}}>
                            <ListItemText primary={t('contact')}/>
                        </ListItem>
                        <ListItem button component={Link} to="/reviews" style={{textDecoration: "none", color: "inherit"}}>
                            <ListItemText primary={t('reviews')}/>
                        </ListItem>
                        <ListItem button component={Link} to="/about" style={{textDecoration: "none", color: "inherit"}}>
                            <ListItemText primary={t('about.title')}/>
                        </ListItem>

                        {/* Language Selector in Drawer */}
                        <ListItem button onClick={() => i18n.changeLanguage('en')}>
                            <ListItemText primary="EN"/>
                        </ListItem>
                        <ListItem button onClick={() => i18n.changeLanguage('ru')}>
                            <ListItemText primary="RU"/>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </AppBar>
    );
}

export default Header;
