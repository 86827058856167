import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography, Box } from "@mui/material";
import { useTranslation } from 'react-i18next'; // Подключаем функцию перевода
import './CourseCard.css'; // Импортируем стили
import SeatsAvailability from './components/SeatsAvailability'; // Импортируем новый компонент

function CourseCard({ course }) {
    const { t, i18n } = useTranslation(); // Получаем функцию перевода

    // Определяем текущий язык
    const currentLanguage = i18n.language;

    // Находим локализованные данные для курса
    const localizedCourse = course.localizations?.find(
        (localization) => localization.language === currentLanguage
    ) || {};

    // Используем локализованные значения или fallback на английский (или другой язык по умолчанию)
    const title = localizedCourse.title || course.title;
    const description = localizedCourse.description || course.description;
    const date = localizedCourse.date || course.date || t('dateToBeAnnounced');
    const price = localizedCourse.price || course.price || t('priceToBeAnnounced');
    const language = (() => {
        const courseLanguage = course.language || "flexible";

        if (courseLanguage === "en") {
            return currentLanguage === "ru" ? "Англ" : "Eng";
        }

        if (courseLanguage === "ru") {
            return currentLanguage === "ru" ? "Рус" : "Rus";
        }

        return courseLanguage;
    })();

    return (
        <div className="course-card">
            {/* Оборачиваем картинку в Link */}
            <Link to={course.link} state={course} style={{ textDecoration: "none" }}>
                <div className="course-image-container">
                    <img src={course.imageSrc} alt={title} className="course-image" />
                    <Typography variant="h5" className="course-title">
                        {title}
                    </Typography>
                </div>
            </Link>

            {/* Добавляем отображение даты */}
            <Box marginBottom={0}>
                <Typography variant="subtitle2" color="textSecondary">
                    <strong>{t('courseDate')}:</strong> {date}
                </Typography>
            </Box>

            <Box marginBottom={0}>
                <Typography variant="subtitle2" color="textSecondary">
                    <strong>{t('coursePrice')}:</strong> {price}
                </Typography>
            </Box>

            <Box marginBottom={2}>
                <Typography variant="subtitle2" color="textSecondary">
                    <strong>{t('language')}:</strong> {language}
                </Typography>
            </Box>

            <Typography variant="body2" color="textSecondary" paragraph>
                {description}
            </Typography>

            {/* Добавляем компонент для отображения занятых и доступных мест */}
            <SeatsAvailability
                totalSeats={course.seats}
                occupiedSeats={course.occupiedSeats}
            />

            {/* Кнопка для подробностей курса */}
            <Link to={course.link} state={course} style={{ textDecoration: "none" }}>
                <Button variant="contained" color="primary">
                    {t('learnMore')}
                </Button>
            </Link>
        </div>
    );
}

export default CourseCard;
