import React from "react";
import { Typography, Container } from "@mui/material";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import CourseList from "./CoursesList.js";
import { useTranslation } from "react-i18next"; // Импортируем хук

// Создаем тему с адаптивными размерами шрифта
let theme = createTheme({
    palette: {
        primary: {
            main: "#1976d2",
        },
        secondary: {
            main: "#ff9800",
        },
    },
    typography: {
        fontFamily: "Roboto, Arial",
        h2: {
            fontWeight: 700,
            fontSize: "2.5rem", // Размер для больших экранов
            "@media (max-width:600px)": {
                fontSize: "1.8rem", // Размер для телефонов
            },
        },
        body1: {
            fontSize: "1.2rem",
        },
    },
});
theme = responsiveFontSizes(theme);

function App() {
    const { t } = useTranslation(); // Используем хук для перевода

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg" sx={{ marginTop: "2rem" }}>
                <Typography
                    variant="h2"
                    align="center"
                    gutterBottom
                    sx={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        whiteSpace: "normal",
                    }}
                >
                    {t('freedivingCourses')} {/* Перевод: "Фридайвинг курсы" */}
                </Typography>
                <Typography
                    variant="body1"
                    align="center"
                    color="textSecondary"
                    paragraph
                >
                    {t('exploreUnderwater')} {/* Перевод: "Исследуй подводный мир" */}
                </Typography>
                <CourseList />
            </Container>
        </ThemeProvider>
    );
}

export default App;
